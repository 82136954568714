import React from 'react';
import { Stack, H, P } from './system';

import { breakpoint } from '../constants';

function NewHero({ location, images }) {
  const webp = images.find(image => image.extension === 'webp');
  const jpg = images.find(image => image.extension === 'jpg');

  return (
    <div
      css={`
        background: ${p => p.theme.colors.white};
        display: grid;
        grid-template-columns: auto;
        grid-gap: 40px;
        padding: 20px;

        @media (min-width: ${breakpoint.sm}) {
          padding: 50px;
          grid-template-columns: auto auto;
        }

        @media (min-width: ${breakpoint.lg}) {
          grid-template-columns: auto 50%;
        }
      `}
    >
      <div
        css={`
          @media (min-width: ${breakpoint.sm}) {
            padding: 0;
            order: 1;
            align-self: center;
          }
        `}
      >
        <div css={``}>
          <picture
            css={`
              max-width: 100%;
            `}
          >
            <source srcSet={webp.publicURL} type="image/webp" />
            <source srcSet={jpg.publicURL} type="image/jpg" />
            <img
              src={jpg.publicURL}
              alt="beers sitting on a porch"
              css={`
                max-width: 100%;
                /* height: auto; */
              `}
            />
          </picture>
        </div>
      </div>
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <Stack>
          <Stack sm>
            <H
              as="h1"
              css={`
                padding-bottom: 10px;
                border-bottom: 4px solid ${p => p.theme.colors.contrast};
              `}
              textColor="primary"
              size={[1.3, 2, 'xl']}
            >
              {location.seo.heading}
            </H>
            {location.mainCopy.map((paragraph, i) => {
              return (
                <div key={`top-paragraph-${i}`}>
                  <P>{paragraph}</P>
                </div>
              );
            })}
          </Stack>
        </Stack>
      </div>
    </div>
  );
}

export default NewHero;
