import React from 'react';
import { Text } from './system';
import { trackEvent, scrollToID } from '../lib';
import { breakpoint } from '../constants';

function SearchField({
  searchTerm = '',
  onSearch = () => {},
  setNumEventsFired = () => {},
  className = '',
}) {
  return (
    <form
      className={className}
      css={`
        flex: 1;
        position: relative;
        width: 100%;

        @media (min-width: ${breakpoint.sm}) {
          max-width: 350px;
        }
        @media (min-width: ${breakpoint.lg}) {
          max-width: 450px;
        }
      `}
      onSubmit={e => {
        e.preventDefault();

        setNumEventsFired(num => num + 1);
        trackEvent({
          action: 'Search from Nav',
          category: 'Page Interactions',
          label: searchTerm,
        });

        scrollToID({ id: 'results-top', offset: 90, behavior: 'smooth' });
      }}
    >
      <input
        css={`
          padding: 8px 12px;
          width: 100%;
          border: 0;
          border-radius: 0;
          min-height: 50px;
          :focus,
          :active {
            outline: 2px dashed ${p => p.theme.colors.contrast};
            outline-offset: 2px;
          }
        `}
        autoComplete="off"
        autoCapitalize="off"
        name="searchbox"
        type="text"
        placeholder="ipa, hoppy, etc"
        value={searchTerm}
        onFocus={e => {
          const target = e.target;
          setTimeout(() => {
            target.setSelectionRange(0, searchTerm.length);
          }, 0);
        }}
        onChange={e => {
          onSearch(e.target.value || '');
        }}
      />
      <div
        css={`
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          height: 100%;
          display: flex;
          align-items: center;
        `}
      >
        <button
          type="submit"
          css={`
            background: ${p => p.theme.colors.primary};
            color: white;
            padding: 2px 10px;
            border-radius: 16px;
            border: none;
            z-index: 12;
            padding-bottom: 4px;
            :active,
            :focus {
              outline: 2px dashed ${props => props.theme.colors.primary};
              outline-radius: 16px;
              outline-offset: 4px;
            }
            :active {
              background: ${p => p.theme.colors.black};
            }
          `}
        >
          <Text sm textColor="white">
            <strong>Search</strong>
          </Text>
        </button>
      </div>
    </form>
  );
}

export default SearchField;
