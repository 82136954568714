import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ExternalLink } from '../components/Links';
import { Stack, H, Text, FadeIn } from '../components/system';
import { breakpoint } from '../constants';
import fallbackImagePNG from '../images/missing-beer-image.png';
import fallbackImageWEBP from '../images/missing-beer-image.webp';
import { trackEvent } from '../lib';
import useIsClient from '../hooks/use-is-client';

export default function BeerCard({
  imageSrc,
  beerNameHTML,
  breweryName,
  breweryLocations,
  globalPromoCode,
  buyUrl,
  productId = '',
  onClickLink = () => {},
  onSearchSuggestedTerm = () => {},
  isPromoCodeFeatureEnabled = false,
}) {
  const { key } = useIsClient();

  const [image, setImage] = useState();
  const [isOfferVisible, setIsOfferVisible] = useState(false);

  const OFFER_BUTTON_TEXT = 'Exclusive Offer';

  function showOffer() {
    // TODO: check if shopify hosted and do the url redirect?
    // TODO: copy the text on click
    setIsOfferVisible(true);

    trackEvent({
      action: 'View Promo Code',
      category: 'Promo Codes',
      label: `buttonText:${OFFER_BUTTON_TEXT} breweryName:${breweryName}`,
    });
  }

  useEffect(() => {
    setTimeout(() => setImage(imageSrc));
  }, [imageSrc]);

  return (
    <Stack key={key}>
      <div
        css={`
          position: relative;
        `}
      >
        <ExternalLink
          noStyles
          css={`
            position: absolute;
            width: 100%;
            height: 100%;
          `}
          to={buyUrl}
        />
        <FadeIn fadeIn={0.3} translateY={false}>
          <picture>
            <source
              srcSet={image?.webp ? `/${image?.webp}` : fallbackImageWEBP}
              type="image/webp"
            />
            {!!image?.jpg && (
              <source srcSet={`/${image?.jpg}`} type="image/jpeg" />
            )}
            <source
              srcSet={image?.png ? `/${image?.png}` : fallbackImagePNG}
              type="image/png"
            />
            <BeerImg
              data-product-id={productId}
              alt="picture of a beer result"
              loading="lazy"
              src={
                image?.png || image?.jpg
                  ? `/${image?.png || image?.jpg}`
                  : fallbackImagePNG
              }
            />
          </picture>
        </FadeIn>
      </div>
      <div>
        <Stack sm>
          {!!globalPromoCode && (
            <div>
              {isOfferVisible ? (
                <FadeIn>
                  <div
                    css={`
                      background: white;
                      padding: 4px;
                      border-radius: 6px;
                      border: 2px dashed ${p => p.theme.colors.primary};
                    `}
                  >
                    <div>{globalPromoCode.description}</div>
                    <div>
                      Code: <strong>{globalPromoCode.code}</strong>
                    </div>
                  </div>
                </FadeIn>
              ) : (
                <button
                  onClick={showOffer}
                  css={`
                    width: 100%;
                    background: ${p => p.theme.colors.primary};
                    color: white;
                    text-align: center;
                    padding: 4px 0;
                    border-radius: 6px;
                    border: none;
                    cursor: pointer;
                    :hover,
                    :focus {
                      text-decoration: underline;
                    }
                  `}
                >
                  <strong>Exclusive Offer</strong>
                </button>
              )}
            </div>
          )}

          <div>
            <H
              as="h2"
              dangerouslySetInnerHTML={{
                __html: beerNameHTML,
              }}
            />
          </div>

          {!!breweryName && (
            <div>
              <Text
                as="button"
                css={`
                  background: inherit;
                  border: none;
                  padding: 0;
                  text-align: left;
                  text-decoration: underline;
                  cursor: pointer;
                `}
                onClick={() => onSearchSuggestedTerm(breweryName)}
                textColor="black"
                sm
              >
                {breweryName}
              </Text>
            </div>
          )}
          {!!breweryLocations && !!breweryLocations.length && (
            <div>
              <Text xs>
                Brewery Location{breweryLocations.length > 1 ? 's' : ''}:{' '}
              </Text>
              <Text xs>{breweryLocations.join(', ')}</Text>
            </div>
          )}
        </Stack>
      </div>
      {!!buyUrl && (
        <div>
          <ExternalLink
            onClick={() =>
              onClickLink({ breweryName, hasPromoCode: !!globalPromoCode })
            }
            to={buyUrl}
          >
            <Text textColor="primary">Buy from Brewery</Text>
          </ExternalLink>
        </div>
      )}
    </Stack>
  );
}

const BeerImg = styled.img`
  width: 100%;
  object-fit: cover;
  background-color: ${p => p.theme.colors.primaryLighter};
  height: 150px;

  @media (min-width: ${breakpoint.sm}) {
    height: 250px;
  }
`;

const LoadingRing = styled.div`
  display: inline-block;

  :after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
