import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import elasticlunr from 'elasticlunr';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container } from '../components/layoutComponents';
import NewHero from '../components/NewHero';
import allLightBeers from '../../.items/light-beers.json';
import beerFinderLogo from '../images/logo--name.svg';
import { InternalLink } from '../components/Links';
import breweries from '../../.items/breweries.json';
import {
  scrollToID,
  filterDelivery,
  getFilteredBreweries,
  trackEvent,
  getStorageItem,
  setStorageItem,
  sortAlphabeticallyExceptThe,
  getBreweryLocations,
  getSearchTermFromURL,
  sortAndMaybeShuffleBeers,
} from '../lib';
import {
  hideMobile,
  Stack,
  Cluster,
  Button,
  Box,
  H,
  Text,
  FadeIn,
} from '../components/system';
import SignupForm from '../components/SignupForm';
import BeerCard from '../components/BeerCard';
import {
  breakpoint,
  EVENT_COUNT_POPUP_THRESHOLD,
  NO_LOCATION_KEY,
} from '../constants';
import searchIndex from '../../.items/search-index.json';
import { useInView } from 'react-intersection-observer';
import { SignupFormContext } from '../contexts/signup-form';
import {
  DeliveryFilter,
  CharacteristicsFilter,
  BeerStylesFilter,
  BreweryFilter,
  BreweryLocationFilter,
} from '../components/filters';
import SearchField from '../components/SearchField';

const idx = elasticlunr.Index.load(searchIndex);

const DELIVERY_LOCATION_KEY = 'user-delivery-location';

const isLocationOpen = !getStorageItem(DELIVERY_LOCATION_KEY);

function filterBreweryLocation(item, filteredBreweryLocations = []) {
  if (!filteredBreweryLocations.length) return true;

  return item?.brewery?.breweryLocations.some(location => {
    return filteredBreweryLocations.includes(location);
  });
}

const LocationTemplate = ({ pageContext, data }) => {
  const locationObj = pageContext?.deliveryLocation;
  const heroImages = data.allFile.edges.map(edge => edge.node);

  const [searchTerm, setSearchTerm] = useState(getSearchTermFromURL() || '');

  const [filteredBeers, setFilteredBeers] = useState(
    sortAndMaybeShuffleBeers(allLightBeers)
  );
  const [deliveryFilter, setDeliveryFilter] = useState(
    locationObj.name !== NO_LOCATION_KEY
      ? locationObj.name
      : getStorageItem(DELIVERY_LOCATION_KEY)
  );
  const [filteredBreweriesList, setFilteredBreweriesList] = useState(
    breweries.breweries
  );
  const [isClean, setIsClean] = useState(true);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const [numEventsFired, setNumEventsFired] = useState(0);
  const { isAllowedToPopUp, setIsOpen } = useContext(SignupFormContext);
  const resultsDisplayLength = isClean ? 20 : filteredBeers.length;

  const [filteredBreweryLocations, setFilteredBreweryLocations] = useState([]);

  useEffect(() => {
    if (!isAllowedToPopUp || numEventsFired !== EVENT_COUNT_POPUP_THRESHOLD)
      return;

    const timer = setTimeout(() => {
      setIsOpen(true);
      trackEvent({
        action: 'Pop up Sign up modal',
        category: 'Registration',
        label: true,
      });
    }, 3000);

    return () => clearTimeout(timer);
  }, [isAllowedToPopUp, numEventsFired, setIsOpen]);

  useEffect(() => {
    if (isClean && inView) {
      setIsClean(false);
    }
  }, [isClean, inView, filteredBeers.length]);

  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: breakpoint.sm,
    })
  );

  const [breweryLocations, setBreweryLocations] = useState(
    getBreweryLocations(allLightBeers).sort()
  );

  useEffect(() => {
    setBreweryLocations(
      getBreweryLocations(
        allLightBeers.filter(item => filterDelivery({ item, deliveryFilter }))
      ).sort()
    );
  }, [deliveryFilter]);

  useEffect(() => {
    if (searchTerm) {
      const searchedBeers = idx
        .search(searchTerm, {
          fileds: {
            beerName: { boost: 2 },
            beerDescription: { boost: 1 },
          },
          bool: 'AND',
          expand: true,
        })
        .map(result => allLightBeers.find(item => item.id === result.ref))
        .filter(item => filterDelivery({ item, deliveryFilter }))
        .filter(item => filterBreweryLocation(item, filteredBreweryLocations));

      setFilteredBeers(sortAndMaybeShuffleBeers(searchedBeers, false));

      return;
    }

    const filteredBeers = allLightBeers
      .filter(item => filterDelivery({ item, deliveryFilter }))
      .filter(item => filterBreweryLocation(item, filteredBreweryLocations));

    setFilteredBeers(sortAndMaybeShuffleBeers(filteredBeers));
  }, [deliveryFilter, filteredBreweryLocations, searchTerm]);

  useEffect(() => {
    setFilteredBreweriesList(
      getFilteredBreweries(
        allLightBeers
          .filter(item => filterDelivery({ item, deliveryFilter }))
          .filter(item => filterBreweryLocation(item, filteredBreweryLocations))
      ).sort(sortAlphabeticallyExceptThe)
    );
  }, [deliveryFilter, filteredBeers, filteredBreweryLocations]);

  useEffect(() => {
    if (getSearchTermFromURL()) {
      setIsMobileFiltersVisible(false);
      scrollToID({ id: 'results-top', offset: 90, behavior: 'smooth' });
    }
  }, []);

  const onSearch = v => {
    setIsClean(true);
    setSearchTerm(v);
  };

  function onSearchFromInput(v) {
    onSearch(v);
  }

  const onSetDeliveryFilter = location => {
    const nextLocation = location === deliveryFilter ? '' : location;

    setDeliveryFilter(nextLocation);
    setStorageItem(DELIVERY_LOCATION_KEY, nextLocation);

    if (isMobile && !!location) {
      scrollToID({ id: 'results-top', offset: 90, behavior: 'smooth' });
    }
    setNumEventsFired(num => num + 1);
  };

  function onSearchSuggestedTerm(term) {
    onSearch(term);
    scrollToID({ id: 'results-top', offset: 90, behavior: 'smooth' });
    setNumEventsFired(num => num + 1);
    trackEvent({
      action: 'Search Suggested Term',
      category: 'Page Interactions',
      label: term,
    });
  }

  function onClickLink({ breweryName, hasPromoCode }) {
    setNumEventsFired(num => num + 1);
    trackEvent({
      action: 'Visit Brewery Link',
      category: 'Page Interactions',
      label: hasPromoCode ? `${breweryName}  isPromoted` : breweryName,
    });
  }

  const [isMobileFiltersVisible, setIsMobileFiltersVisible] = useState(
    isLocationOpen
  );
  function onSetMobileFiltersVisible() {
    setIsMobileFiltersVisible(!isMobileFiltersVisible);
  }

  const [isMobileNavVisible, setIsMobileNavVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(
    typeof window !== 'undefined' ? window?.pageYOffset : 0
  );

  useEffect(() => {
    if (!isMobile) return;

    function scrollListener() {
      const currentprevScrollPos = window.pageYOffset;

      const isVisible =
        currentprevScrollPos < 200 || prevScrollPos > currentprevScrollPos;
      setIsMobileNavVisible(isVisible);
      setPrevScrollPos(currentprevScrollPos);
    }

    document.addEventListener('scroll', scrollListener);

    return () => document.removeEventListener('scroll', scrollListener);
  }, [isMobile, prevScrollPos]);

  return (
    <Layout>
      <SEO
        title={locationObj?.seo?.title}
        cityLocation={
          locationObj.name !== NO_LOCATION_KEY ? locationObj.name : ''
        }
        canonical={pageContext?.canonicalSEO}
        description={locationObj?.seo?.description}
      />
      <Nav
        css={`
          transform: translateY(${isMobileNavVisible ? '0' : '-150px'});
          transition: transform 0.3s;
        `}
      >
        <Container>
          <Stack sm>
            <Cluster
              css={`
                justify-content: space-between;
                align-items: center;
              `}
            >
              <div
                css={`
                  display: flex;
                  align-items: center;
                `}
              >
                <picture
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <source
                    srcSet={beerFinderLogo}
                    media={`(min-width: ${breakpoint.sm})`}
                  />
                  <Logo
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    css={`
                      cursor: pointer;
                    `}
                    srcSet={beerFinderLogo}
                    // src={beerFinderLogoSmall}
                    alt="Beer Finder logo"
                  />
                </picture>
                <div
                  css={`
                    display: none;
                    @media (min-width: ${breakpoint.sm}) {
                      justify-self: start;
                      display: inline-block;
                      margin-left: 40px;
                    }
                  `}
                >
                  <InternalLink
                    noStyles
                    css={`
                      border-bottom: 3px solid ${p => p.theme.colors.white};
                      :hover {
                        border-bottom: 3px solid ${p => p.theme.colors.contrast};
                      }
                    `}
                    to="/blog"
                  >
                    <Text textColor="white">
                      <strong>Blog</strong>
                    </Text>
                  </InternalLink>
                </div>
              </div>
              <SearchField
                css={`
                  display: none;
                  @media (min-width: ${breakpoint.sm}) {
                    display: inline-block;
                  }
                `}
                searchTerm={searchTerm}
                onSearch={onSearchFromInput}
                setNumEventsFired={setNumEventsFired}
              />
              <div
                css={`
                  @media (min-width: ${breakpoint.sm}) {
                    display: none;
                  }
                `}
              >
                <InternalLink
                  noStyles
                  css={`
                    border-bottom: 3px solid ${p => p.theme.colors.white};
                    :hover {
                      border-bottom: 3px solid ${p => p.theme.colors.contrast};
                    }
                  `}
                  to="/blog"
                >
                  <Text textColor="white">
                    <strong>Blog</strong>
                  </Text>
                </InternalLink>
              </div>
            </Cluster>

            <SearchField
              css={`
                @media (min-width: ${breakpoint.sm}) {
                  display: none;
                }
              `}
              searchTerm={searchTerm}
              onSearch={onSearchFromInput}
              setNumEventsFired={setNumEventsFired}
            />
          </Stack>
        </Container>
      </Nav>
      <SignupForm isOpen={true} />
      <Box py={[2, 4]}>
        <FadeIn>
          <Container>
            <Stack>
              <NewHero images={heroImages} location={locationObj} />
              <Box
                css={`
                  > * :not(:last-child) {
                    margin-right: 20px;
                  }
                  min-height: 100vh;
                `}
                display="flex"
                alignItems="flex-start"
              >
                {!isMobile && (
                  <Sidebar hideMobile>
                    <Stack>
                      <H as="h2" lg>
                        Filters
                      </H>
                      <DeliveryFilter
                        onSetDeliveryFilter={onSetDeliveryFilter}
                        deliveryFilter={deliveryFilter}
                        initialIsOpen={isLocationOpen}
                      />
                      <BeerStylesFilter
                        onSearchSuggestedTerm={onSearchSuggestedTerm}
                      />
                      <CharacteristicsFilter
                        onSearchSuggestedTerm={onSearchSuggestedTerm}
                      />
                      <BreweryFilter
                        onSearchSuggestedTerm={onSearchSuggestedTerm}
                        filteredBreweries={filteredBreweriesList}
                      />
                      <BreweryLocationFilter
                        setFilteredBreweryLocations={
                          setFilteredBreweryLocations
                        }
                        isMobile={isMobile}
                        filteredBreweryLocations={filteredBreweryLocations}
                        allBreweryLocations={breweryLocations}
                      />
                    </Stack>
                  </Sidebar>
                )}

                <Box
                  css={`
                    flex: 1;
                  `}
                >
                  <main>
                    <Stack sm>
                      {isMobile && (
                        <Stack sm>
                          <div>
                            <Button
                              css={`
                                display: flex;
                                align-items: center;
                              `}
                              onClick={onSetMobileFiltersVisible}
                            >
                              {isMobileFiltersVisible ? <Remove /> : <Add />}
                              <strong>Filters</strong>
                            </Button>
                          </div>

                          <div
                            css={`
                              display: ${isMobileFiltersVisible
                                ? 'block'
                                : 'none'};
                            `}
                          >
                            <Stack>
                              <DeliveryFilter
                                onSetDeliveryFilter={onSetDeliveryFilter}
                                deliveryFilter={deliveryFilter}
                                initialIsOpen={isLocationOpen}
                              />
                              <BeerStylesFilter
                                onSearchSuggestedTerm={onSearchSuggestedTerm}
                                initialIsOpen={false}
                              />
                              <CharacteristicsFilter
                                onSearchSuggestedTerm={onSearchSuggestedTerm}
                              />
                              <BreweryFilter
                                onSearchSuggestedTerm={onSearchSuggestedTerm}
                                filteredBreweries={filteredBreweriesList}
                              />
                              <BreweryLocationFilter
                                setFilteredBreweryLocations={
                                  setFilteredBreweryLocations
                                }
                                isMobile={isMobile}
                                filteredBreweryLocations={
                                  filteredBreweryLocations
                                }
                                allBreweryLocations={breweryLocations}
                              />
                            </Stack>
                          </div>
                        </Stack>
                      )}
                      <Stack sm id="results-top">
                        {!!filteredBeers.length && (
                          <div>
                            <Text sm>
                              Displaying <strong>{filteredBeers.length}</strong>{' '}
                              results from{' '}
                              <strong>
                                {getFilteredBreweries(filteredBeers).length}
                              </strong>{' '}
                              breweries
                            </Text>
                          </div>
                        )}
                        <Cluster>
                          {!filteredBeers.length && (
                            <div>
                              No beers matched your search. Try something else!
                            </div>
                          )}
                          {filteredBeers
                            .slice(0, resultsDisplayLength)
                            .map((item, index) => {
                              return (
                                <div
                                  ref={index === 11 ? ref : null}
                                  key={item.id}
                                  css={`
                                    width: calc(50% - 8px);
                                    padding-bottom: 16px;

                                    @media (min-width: ${breakpoint.sm}) {
                                      width: calc(25% - 16px);
                                      padding-bottom: 26px;
                                    }
                                  `}
                                >
                                  <BeerCard
                                    imageSrc={item?.beer?.image?.localSrc}
                                    productId={item?.beer?.productId}
                                    beerNameHTML={item?.beer?.name}
                                    breweryName={item?.brewery?.name}
                                    globalPromoCode={
                                      item?.brewery?.globalPromoCode
                                    }
                                    breweryLocations={
                                      item?.brewery?.breweryLocations
                                    }
                                    buyUrl={item?.beer?.urlBuy}
                                    onClickLink={onClickLink}
                                    onSearchSuggestedTerm={
                                      onSearchSuggestedTerm
                                    }
                                  />
                                </div>
                              );
                            })}
                        </Cluster>
                      </Stack>
                    </Stack>
                  </main>
                </Box>
              </Box>
            </Stack>
          </Container>
        </FadeIn>
      </Box>
    </Layout>
  );
};

const Sidebar = styled.div`
  position: sticky;
  top: 120px;
  width: 25%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 8.9rem);
  padding-bottom: 40px;
  padding-bottom: 300px;
  padding-left: 6px; /* This is a hack to account for the overflow-hidden when outline is focused */
  ${hideMobile}
`;

const Logo = styled.img`
  max-width: 150px;
  @media (min-width: ${breakpoint.sm}) {
    max-width: 200px;
  }
`;

const Nav = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${p => p.theme.colors.black};

  @media (min-width: ${breakpoint.sm}) {
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
  }
`;

export default LocationTemplate;

export const query = graphql`
  query blah($cityNameRegex: String! = "/beer-delivery-general/") {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        base: { regex: $cityNameRegex }
      }
    ) {
      edges {
        node {
          publicURL
          extension
        }
      }
    }
  }
`;
