import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import locations from '../../.items/locations.json';
import { trackEvent, scrollToID } from '../lib';
import { Stack, Cluster, Button, P, Text } from '../components/system';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import { beerStyles, beerCharacteristics, breakpoint } from '../constants';

function ToolTipCity() {
  return (
    <ToolTip>
      <P sm>
        If your city is not on the list, select{' '}
        <strong>Ontario&nbsp;(Other)</strong> to see beers that deliver
        Ontario-wide.
      </P>
    </ToolTip>
  );
}

function ToolTip({ children }) {
  return (
    <div
      css={`
        display: flex;
      `}
    >
      <div
        css={`
          padding: 10px;
        `}
      >
        <span role="img" aria-label="lightbulb tooltip">
          💡
        </span>
      </div>
      {children}
    </div>
  );
}

export function DeliveryFilter({
  onSetDeliveryFilter = () => {},
  deliveryFilter,
  initialIsOpen = false,
}) {
  const [isDeliveryLocationsOpen, setIsDeliveryLocationsOpen] = useState(
    initialIsOpen
  );
  const [inputValue, setInputValue] = useState('');

  function setDeliveryFilter(location) {
    setInputValue('');
    const nextLocation = deliveryFilter === location ? '' : location;
    onSetDeliveryFilter(nextLocation);

    if (!nextLocation) setIsDeliveryLocationsOpen(true);

    if (nextLocation) {
      setIsDeliveryLocationsOpen(false);

      trackEvent({
        action: 'Set Delivery Location',
        category: 'Page Interactions',
        label: nextLocation,
      });
    }
  }

  function setIsOpen() {
    setIsDeliveryLocationsOpen(!isDeliveryLocationsOpen);

    trackEvent({
      action: 'Toggle Delivery Locations',
      category: 'Page Interactions',
      label: `${!isDeliveryLocationsOpen}`,
    });
  }

  const filteredLocations = [
    ...locations
      .filter(item => item !== 'Ontario')
      .filter(location =>
        inputValue ? location.toLowerCase().includes(inputValue) : true
      ),
    'Ontario',
  ];

  return (
    <>
      {!!deliveryFilter && (
        <div>
          <Text>
            <strong>Delivery Location</strong>
          </Text>
          <div>
            <Button
              variant="pill"
              selected={true}
              onClick={() => setDeliveryFilter(deliveryFilter)}
              css={`
                display: flex;
                align-items: center;
              `}
            >
              <span>
                {deliveryFilter === 'Ontario'
                  ? 'Ontario (Other)'
                  : deliveryFilter}
              </span>
              <Close />
            </Button>
          </div>
        </div>
      )}
      {!deliveryFilter && (
        <div>
          <Stack xs>
            <Stack xs>
              <div>
                <Button
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                  onClick={setIsOpen}
                >
                  {isDeliveryLocationsOpen ? <Remove /> : <Add />}
                  <span>
                    <strong>Set Delivery Location</strong>
                  </span>
                </Button>
              </div>
              {!isDeliveryLocationsOpen && !!deliveryFilter && (
                <div>
                  <Text sm>({deliveryFilter})</Text>
                </div>
              )}
            </Stack>

            <Stack
              css={`
                display: ${isDeliveryLocationsOpen ? 'block' : 'none'};
              `}
              sm
            >
              <div>
                <Stack xs>
                  <Text as="label" htmlFor="delivery-location-input">
                    <strong>Set your delivery location</strong>
                  </Text>
                  <div>
                    <input
                      id="delivery-location-input"
                      css={`
                        width: 100%;
                        padding: 8px 8px;
                        border: none;
                        border-radius: 0;
                      `}
                      type="text"
                      autoComplete="off"
                      autoCapitalize="off"
                      placeholder="Toronto, Ottawa, Hamilton, etc."
                      value={inputValue}
                      onChange={e =>
                        setInputValue(e.target.value.toLowerCase())
                      }
                    />
                  </div>
                </Stack>
              </div>

              <div>
                <Stack xs>
                  {filteredLocations.length ? (
                    <>
                      {filteredLocations.map(location => {
                        const loc =
                          location === 'Ontario' ? 'Ontario (Other)' : location;
                        return (
                          <>
                            <div key={`delivery-locations-${location}`}>
                              <Button
                                variant="pill"
                                selected={location === deliveryFilter}
                                onClick={() => setDeliveryFilter(location)}
                              >
                                {loc}
                              </Button>
                            </div>
                            {location === 'Ontario' && <ToolTipCity />}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <Button
                      variant="pill"
                      onClick={() => setDeliveryFilter('Ontario')}
                    >
                      Ontario (Other)
                    </Button>
                  )}
                </Stack>
              </div>
            </Stack>
          </Stack>
        </div>
      )}
    </>
  );
}

export function BreweryLocationFilter({
  setFilteredBreweryLocations = () => {},
  filteredBreweryLocations,
  allBreweryLocations,
  isMobile,
  initialIsOpen = false,
}) {
  const [isDeliveryLocationsOpen, setIsDeliveryLocationsOpen] = useState(
    initialIsOpen
  );
  const [inputValue, setInputValue] = useState('');

  function onSelectLocation(location) {
    setInputValue('');
    setFilteredBreweryLocations(currentLocations => {
      const locationIndex = currentLocations.findIndex(
        item => item === location
      );

      if (locationIndex === -1) {
        return [...currentLocations, location];
      }
      return currentLocations.filter((_, i) => i !== locationIndex);
    });

    if (isMobile && isDeliveryLocationsOpen) {
      setIsOpen();
    }

    scrollToID({ id: 'results-top', offset: 90, behavior: 'smooth' });

    trackEvent({
      action: 'Filter Brewery Location',
      category: 'Page Interactions',
      label: location,
    });
  }

  function setIsOpen() {
    setIsDeliveryLocationsOpen(!isDeliveryLocationsOpen);

    trackEvent({
      action: 'Toggle Delivery Locations',
      category: 'Page Interactions',
      label: `${!isDeliveryLocationsOpen}`,
    });
  }

  const searchedLocations = allBreweryLocations
    .filter(location =>
      inputValue ? location.toLowerCase().includes(inputValue) : true
    )
    .filter(item => !filteredBreweryLocations.includes(item));
  return (
    <>
      <div>
        <Stack xs>
          <Stack xs>
            <div>
              <Button
                css={`
                  display: flex;
                  align-items: center;
                `}
                onClick={setIsOpen}
              >
                {isDeliveryLocationsOpen ? <Remove /> : <Add />}
                <span>
                  <strong>Brewery Locations</strong>
                </span>
              </Button>
            </div>
          </Stack>

          <Stack
            css={`
              display: ${isDeliveryLocationsOpen ? 'block' : 'none'};
            `}
            sm
          >
            <div>
              <Stack sm>
                <div>
                  <Stack>
                    <ToolTip>
                      <P sm>
                        Use this filter to find beer delivery from a brewery
                        near you
                      </P>
                    </ToolTip>
                  </Stack>
                </div>
                <div>
                  <Text as="label" htmlFor="brewery-location-location-input">
                    <strong>Filter results by brewery location</strong>
                  </Text>
                  <div>
                    <input
                      id="brewery-location-location-input"
                      css={`
                        width: 100%;
                        padding: 8px 8px;
                        border: none;
                        border-radius: 0;
                      `}
                      type="text"
                      autoComplete="off"
                      autoCapitalize="off"
                      placeholder="Toronto, Ottawa, Hamilton, etc."
                      value={inputValue}
                      onChange={e =>
                        setInputValue(e.target.value.toLowerCase())
                      }
                    />
                  </div>
                </div>
              </Stack>
            </div>

            <div>
              <Stack xs>
                {filteredBreweryLocations.length ? (
                  <>
                    {filteredBreweryLocations.map(location => {
                      return (
                        <div>
                          <Button
                            variant="pill"
                            selected={true}
                            onClick={() => onSelectLocation(location)}
                            css={`
                              display: flex;
                              align-items: center;
                            `}
                          >
                            <span>{location}</span>
                            <Close />
                          </Button>
                        </div>
                      );
                    })}
                  </>
                ) : null}
                {searchedLocations.length ? (
                  <>
                    {searchedLocations.map(location => {
                      return (
                        <>
                          <div key={`delivery-locations-${location}`}>
                            <Button
                              variant="pill"
                              onClick={() => onSelectLocation(location)}
                            >
                              {location}
                            </Button>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <Text>No results</Text>
                )}
              </Stack>
            </div>
          </Stack>
          <Cluster
            css={`
              display: ${isDeliveryLocationsOpen ? 'none' : 'block'};
            `}
            sm
          >
            {filteredBreweryLocations.length ? (
              <>
                {filteredBreweryLocations.map(location => {
                  return (
                    <div key={`brewery-location-${location}`}>
                      <Button
                        variant="pill"
                        selected={true}
                        onClick={() => onSelectLocation(location)}
                        css={`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <span>{location}</span>
                        <Close />
                      </Button>
                    </div>
                  );
                })}
              </>
            ) : null}
          </Cluster>
        </Stack>
      </div>
    </>
  );
}

export function BreweryFilter({
  onSearchSuggestedTerm = () => {},
  shouldCloseOnMobileSelect = true,
  filteredBreweries,
}) {
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: breakpoint.sm,
    })
  );
  const [isBreweriesOpen, setIsBreweriesOpen] = useState(false);

  function onSetIsBreweriesOpen() {
    setIsBreweriesOpen(!isBreweriesOpen);

    trackEvent({
      action: 'Toggle Breweries',
      category: 'Page Interactions',
      label: `${!isBreweriesOpen}`,
    });
  }

  return (
    <div>
      <Stack sm>
        <div>
          <Button
            css={`
              display: flex;
              align-items: center;
            `}
            onClick={onSetIsBreweriesOpen}
          >
            {isBreweriesOpen ? <Remove /> : <Add />}
            <strong>Breweries</strong>
          </Button>
        </div>

        <div
          css={`
            display: ${isBreweriesOpen ? 'block' : 'none'};
          `}
        >
          <Stack xs>
            {filteredBreweries.map(term => (
              <div key={`breweries-${term}`}>
                <Button
                  variant="pill"
                  onClick={() => {
                    isMobile &&
                      shouldCloseOnMobileSelect &&
                      setIsBreweriesOpen(!isBreweriesOpen);

                    onSearchSuggestedTerm(term);
                  }}
                >
                  {term}
                </Button>
              </div>
            ))}
          </Stack>
        </div>
      </Stack>
    </div>
  );
}

export function CharacteristicsFilter({ onSearchSuggestedTerm = () => {} }) {
  const [isCharacteristicsOpen, setIsCharacteristicsOpen] = useState(false);
  function onSetIsCharacteristicsOpen() {
    setIsCharacteristicsOpen(!isCharacteristicsOpen);

    trackEvent({
      action: 'Toggle Beer Characteristics',
      category: 'Page Interactions',
      label: `${!isCharacteristicsOpen}`,
    });
  }
  return (
    <div>
      <Stack sm>
        <Button
          css={`
            display: flex;
            align-items: center;
          `}
          onClick={onSetIsCharacteristicsOpen}
        >
          {isCharacteristicsOpen ? <Remove /> : <Add />}
          <strong>Characteristics</strong>
        </Button>

        <div
          css={`
            display: ${isCharacteristicsOpen ? 'block' : 'none'};
          `}
        >
          <Cluster sm>
            {beerCharacteristics.map(term => (
              <Button
                variant="pill"
                key={`characteristic-${term}`}
                onClick={() => onSearchSuggestedTerm(term)}
              >
                {term}
              </Button>
            ))}
          </Cluster>
        </div>
      </Stack>
    </div>
  );
}

export function BeerStylesFilter({
  onSearchSuggestedTerm,
  initialIsOpen = true,
}) {
  const [isStylesOpen, setIsStylesOpen] = useState(initialIsOpen);

  function onSetIsStylesOpen() {
    setIsStylesOpen(!isStylesOpen);

    trackEvent({
      action: 'Toggle Beer Styles',
      category: 'Page Interactions',
      label: `${!isStylesOpen}`,
    });
  }
  return (
    <div>
      <Stack sm>
        <Button
          css={`
            display: flex;
            align-items: center;
          `}
          onClick={onSetIsStylesOpen}
        >
          {isStylesOpen ? <Remove /> : <Add />}
          <strong>Beer Styles</strong>
        </Button>

        <div
          css={`
            display: ${isStylesOpen ? 'block' : 'none'};
          `}
        >
          <Cluster sm>
            {beerStyles.map(term => (
              <Button
                variant="pill"
                key={`style-${term}`}
                onClick={() => onSearchSuggestedTerm(term)}
              >
                {term}
              </Button>
            ))}
          </Cluster>
        </div>
      </Stack>
    </div>
  );
}
